/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from "react";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
import { onError } from "apollo-link-error";
import { from } from "apollo-link";
import { AppContext } from "./contexts/app.context";

let lastRequestID = 0;
const clientUUID = Math.round(Math.random() * 10000);

const GraphQLProvider = ({ children }: any) => {
  // Set state value for apollo client to prevent new clients on rerenders
  const [apolloClient, setClient]: any = useState({});
  // Auth hooks
  const appContext = useContext(AppContext);
  const token = appContext.state.id_token;
  // Create authentication middleware to add id_token to requests
  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
        "x-api-key": process.env.REACT_APP_API_GATEWAY_KEY,
      },
    };
  });

  // Handle errors client side
  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors) console.log("[GraphQL Error]: ", graphQLErrors);
    if (networkError) console.log("[Network Error]: ", networkError);
  });

  // Apply request tracking middleware
  const jaeger = setContext(({ operationName, headers }: any) => {
    // Only increment request count if a operation exists
    if (operationName) lastRequestID += 1;
    return {
      headers: {
        ...headers,
        "jaeger-baggage": `session=${clientUUID}, request=${lastRequestID}`,
        jaegersession: clientUUID,
        jaegerrequest: lastRequestID,
      },
    };
  });

  // Create Http/WS link
  const httpLink = createHttpLink({
    uri:
      process.env.REACT_APP_APOLLO_STRIPE_SERVER ||
      "http://localhost:4005/graphql",
  });

  // create an inmemory cache instance for caching graphql data
  const cache = new InMemoryCache();

  // Create apollo client
  const client = new ApolloClient({
    link: from([jaeger, errorLink, authLink, httpLink]),
    cache,
  });

  // Only set apollo client on first render
  useEffect(() => {
    setClient(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
