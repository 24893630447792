import React, {useContext} from 'react';
import * as S from './TopUpList.styles';
import {SelectItemTick} from '@bp/ui-components/web/index.js';
import {AppContext} from '../../../contexts/app.context';

export interface IProps {
  amounts: number[];
}

export default (props: IProps) => {
  const {amounts} = props;

  const appContext = useContext(AppContext);

  const onPress = (amount: number) => {
    appContext.setState({...appContext.state, topUpValue: amount});
  };
  return (
    <>
      <S.HeadingContainer>
        <S.HeadingText
          testID="How much do you want to top up?"
          accessibilityLabel="How much do you want to top up?">
          How much do you want to top up?
        </S.HeadingText>
      </S.HeadingContainer>
      {amounts.map((amount, i) => {
        return (
          <S.ItemContainer key={i}>
            <SelectItemTick
              onPress={() => onPress(amount)}
              accessibilityLabel={`Top up your credit by ${amount.toFixed(
                2,
              )} pounds`}
              testID={`Top up your credit by ${amount.toFixed(2)} pounds`}
              active={appContext.state.topUpValue === amount}
              text={`£${amount.toFixed(2)}`}></SelectItemTick>
          </S.ItemContainer>
        );
      })}
    </>
  );
};
