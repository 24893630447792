import React from "react";
import { Main } from "./components/Templates";
import StyleWrapper from "./StyleWrapper";
import Routes from "./Routes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppProvider } from "./contexts/app.context";
import GraphQLProvider from "./provider";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const App = () => (
  <div>
    <title>Stripe Payments</title>
    <meta name="description" content="payments microsite" />
    <meta name="charSet" charSet="utf-8" />
    <meta name="keywords" content="template" />
    <meta
      name="viewport"
      content="minimum-scale=1,initial-scale=1, width=device-width, shrink-to-fit=no"
    />
    <meta name="theme-color" content="black" />
    <link rel="icon" href="static/favicon.ico" />
    <link rel="apple-touch-icon" href="static/favicon.ico" />
    <AppProvider>
      <GraphQLProvider>
        <StyleWrapper>
          <Main>
            <Elements stripe={stripePromise}>
              <Routes />
            </Elements>
          </Main>
        </StyleWrapper>
      </GraphQLProvider>
    </AppProvider>
  </div>
);

export default App;
