import React from "react";
import * as S from "./ErrorBanner.styles";
import { Exclamation, theme } from "@bp/ui-components/web/index.js";
interface IProps {
  errorMessage: string;
}
const ErrorBanner = (props: IProps) => {
  const { errorMessage } = props;
  return (
    <S.ErrorWrapper theme={theme}>
      <S.IconWrapper>
        <Exclamation color="white" />
      </S.IconWrapper>
      <S.TextWrapper>
        <S.ErrorHeading theme={theme}>Something went wrong</S.ErrorHeading>
        <S.ErrorText theme={theme}>{errorMessage}</S.ErrorText>
      </S.TextWrapper>
    </S.ErrorWrapper>
  );
};

export default ErrorBanner;
