import styled from "styled-components/native";

export const ErrorWrapper = styled.View`
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 30px;
  padding: 20px;
  background: ${(props: any) => props.theme.errorNotification.background};
  display: flex;
  flex-direction: row;
`;

export const TextWrapper = styled.View`
  margin-left: 17px;
  flex-shrink: unset;
`;

export const IconWrapper = styled.View`
flex-shrink: 0;
min-width: 20px;
margin-top: 10px;
`

export const ErrorHeading = styled.Text`
  font-size: 16px;
  padding-bottom: 5px;
  color: ${(props: any) => props.theme.errorNotification.color};
`;

export const ErrorText = styled.Text`
  font-size: 12px;
  color: ${(props: any) => props.theme.errorNotification.color};
`;
