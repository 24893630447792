import gql from "graphql-tag";

export const CREATE_PAYMENT_INTENT = gql`
  query CreatePaymentIntent($stripeId: String, $sfId: String, $amount: Float) {
    createPaymentIntent(stripeId: $stripeId, sfId: $sfId, amount: $amount) {
      data
    }
  }
`;

export const GET_STRIPE_CUSTOMER = gql`
  query GetStripeCustomer($stripeId: String, $sfId: String, $email: String) {
    getCustomer(stripeId: $stripeId, sfId: $sfId, email: $email) {
      data
    }
  }
`;

export const CREATE_SETUP_INTENT = gql`
  query SetupIntent($stripeId: String, $sfId: String) {
    setupIntent(stripeId: $stripeId, sfId: $sfId) {
      data
    }
  }
`;

export const GET_CARDS = gql`
  query GetSavedCards($stripeId: String, $sfId: String) {
    getSavedCards(stripeId: $stripeId, sfId: $sfId) {
      data {
        id
        card {
          brand
          last4
          exp_month
          exp_year
        }
      }
    }
  }
`;
