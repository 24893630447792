import React from "react";
import { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { PageNotFound } from "./components/Pages";

const HomeLazy = lazy(() => import("./components/Pages/Home/Home"));
const TopUpLazy = lazy(() => import("./components/Pages/TopUp/TopUp"));
const AddMethod = lazy(() => import("./components/Pages/AddMethod/AddMethod"));

const Routes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <Route path="/" exact component={HomeLazy} />
      <Route path="/topup" exact component={TopUpLazy} />
      <Route path="/method" exact component={AddMethod} />
      <Route component={PageNotFound} />
    </Switch>
  </Suspense>
);

export default Routes;
