import styled from 'styled-components/native';

interface PaymentMethod {
  active: boolean;
}

export const PaymentMethod = styled.TouchableOpacity<PaymentMethod>`
  background: ${(props: any) => props.theme.selectItem.disabled.background};
  height: 98px;
  width: 100%;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const PaymentMethodText = styled.Text`
  align-self: flex-start;
  flex-direction: row;
  text-align: center;
  font-size: 16px;
`;

export const PaymentMethodDetails = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CardDetails = styled.Text`
  padding-right: 20px;
`;

export const Card = styled.View`
  padding-right: 20px;
`;

export const DetailsText = styled.Text`
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 12px;
  color: ${(props: any) => props.theme.paymentCards.greyText};
`;
