import React from "react";
import styled from "styled-components/native";

const Wrapper = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Overview = () => (
  <Wrapper>
    <p>Home</p>
  </Wrapper>
);

export default Overview;
