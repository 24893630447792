import React, { useState, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CREATE_SETUP_INTENT } from "../../../graphql/queries";
import * as S from "./AddMethod.styles";
import { Button } from "@bp/ui-components/web/index.js";
import { withRouter } from "react-router-dom";
import { ActivityIndicator } from "react-native";
import { ErrorBanner, CountryCodeSelect } from "../../Molecules";
import { AppContext } from "../../../contexts/app.context";
import { countries } from "./Countries";

interface CardData {
  id: string;
  card: Card;
}
interface Card {
  brand: string;
  last4: string;
}
interface Options {
  payment_method: {
    card: any;
    billing_details: {
      name: string;
      address: {
        country: string;
        postal_code: string;
      };
    };
  };
}
interface IProps {
  history: any;
}

const AddMethod = (props: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const appContext = useContext(AppContext);
  const { data, loading } = useQuery(CREATE_SETUP_INTENT, {
    variables: {
      stripeId: appContext.state.stripeId,
      sfId: appContext.state.sfId,
    },
  });
  const [name, onChangeName] = useState("");

  const [errorState, setErrorState] = useState(false);

  const [pageLoading, setLoading] = useState(false);

  const handlePress = async () => {
    if (!stripe || !elements) {
      setLoading(true);
      return;
    }
    const options = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          address: { country: appContext.country },
        },
      },
    } as Options;
    const result = await stripe.confirmCardSetup(
      data.setupIntent.data,
      options
    );
    setLoading(true);
    if (result.error) {
      setErrorState(true);
      setLoading(false);
      //Basic GA call more Params needs to be added
      // googleAnalyticsFnWeb.googleAnalyticsEvent(
      //   "Payment_Card_Stored_Failure",
      //   "Payment Card Stored Failure"
      // );
    } else {
      //Basic GA call more Params needs to be added
      // googleAnalyticsFnWeb.googleAnalyticsEvent(
      //   "Payment_Card_Stored_Success",
      //   "Payment Card Stored Success"
      // );
      props.history.push("/topup");
    }
  };
  const handleChange = (event: any) => {
    appContext.setCountry(event.target.value);
  };
  const setDefault = (defaultValue: any) => {
    appContext.setCountry(defaultValue);
  };
  return (
    <S.CardFormWrapper>
      {errorState === true && (
        <ErrorBanner errorMessage="We couldn't add your payment card. Please check your details before trying again."></ErrorBanner>
      )}
      <S.HeadingText>Card details</S.HeadingText>
      <S.CardWrapper>
        <S.CardElementWrapper>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </S.CardElementWrapper>
      </S.CardWrapper>
      <S.HeadingText>Name on card</S.HeadingText>
      <S.CardWrapper>
        <S.InputField
          accessibilityLabel="Enter the name as it appears on card"
          testID="Enter the name as it appears on card"
          placeholder="Name as it appears on card"
          onChangeText={(text) => onChangeName(text)}
          value={name}
        ></S.InputField>
      </S.CardWrapper>
      <S.HeadingText>Country</S.HeadingText>
      <S.CardWrapper>
        <CountryCodeSelect
          defaultValue={"GB"}
          value={appContext.country}
          handleChange={handleChange}
          setDefault={setDefault}
          countries={countries}
        ></CountryCodeSelect>
      </S.CardWrapper>
      <S.ButtonWrapper>
        <Button
          accessibilityLabel="Add payment card"
          testID="Add payment card"
          type="primary"
          onPress={handlePress}
          disabled={pageLoading}
        >
          {pageLoading || loading ? (
            <S.Loading>
              <ActivityIndicator />
            </S.Loading>
          ) : (
            "Add payment card"
          )}
        </Button>
      </S.ButtonWrapper>
    </S.CardFormWrapper>
  );
};

export default withRouter(AddMethod);
