import styled from "styled-components/native";

export const TopUpFormWrapper = styled.View`
  padding: 20px;
  background-color: #F5F5F5;
  height: 100vh;
`;

export const BottomButton = styled.View`
  position: fixed !important;
  bottom: 20px;
  width: calc(100% - 40px);
`;

export const Loading = styled.View`
  width: 100%;
  height: 90vh;
`;

export const HeadingText = styled.Text`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const NoPaymentText = styled.Text`
  font-size: 16px;
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 70;
`;

export const SuccessText = styled.Text`
  margin-left: 15px;
  font-size: 16px;
  line-height: 19px;
  color: #006def;
`;

export const ResultWrapper = styled.View`
  text-align: center;
`;

export const IconTextContainer = styled.View`
  margin: 20px 0 21px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
