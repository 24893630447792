import React from "react";
import styled from "styled-components/native";
import { RouteComponentProps } from "react-router-dom";

const Wrap404 = styled.View`
  font-size: 48px;
`;

interface IProps {
  location: { pathname: RouteComponentProps };
}

const PageNotFound = (props: IProps) => {
  const { location } = props;
  return (
    <Wrap404>
      <span>{`${location.pathname} could not be found`}</span>
    </Wrap404>
  );
};

export default PageNotFound;
