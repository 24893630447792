import React, {useEffect} from 'react';
import * as S from './CountryCodeSelect.styles';

export interface IProps {
  defaultValue: string;
  setDefault: any;
  value: string;
  handleChange: any;
  countries: Country[];
}

export interface Country {
  code: string;
  name: string;
}

const CountryCodeSelect = (props: IProps) => {
  const {defaultValue, value, handleChange, setDefault, countries} = props;

  useEffect(() => {
    setDefault(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <S.Dropdown value={value} onChange={handleChange}>
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </S.Dropdown>
      <S.Arrow />
    </S.Wrapper>
  );
};

export default CountryCodeSelect;
