import React, { useState, createContext } from "react";

type Method = {
  id: string;
  type: string;
  expiry: string;
  digits: string;
};
type AppContextState = {
  stripeId: string;
  sfId: string;
  email: string;
  id_token: string;
  topUpValue: number;
  paymentMethod: Method;
  paymentIntent: string;
};
const appCtxDefaultValue = {
  state: {
    sfId: "",
    stripeId: "",
    email: "",
    id_token: "",
    topUpValue: 0,
    paymentMethod: { id: "", type: "", expiry: "", digits: "" },
    paymentIntent: "",
  },
  setState: (state: AppContextState) => {},
  topUpFormState: "selectAmount",
  setTopUpFormState: (topUpFormState: string) => {},
  savedCardData: [],
  setSavedCardData: (savedCardData: []) => {},
  country: "",
  setCountry: (country: string) => {},
};
export const AppContext = createContext(appCtxDefaultValue);

export interface IProviderProps {
  children?: any;
}

export const AppProvider = (props: IProviderProps) => {
  const [state, setState] = useState(appCtxDefaultValue.state);
  const [topUpFormState, setTopUpFormState] = useState("selectAmount");
  const [savedCardData, setSavedCardData] = useState([]);
  const [country, setCountry] = useState("");

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        topUpFormState,
        setTopUpFormState,
        savedCardData,
        setSavedCardData,
        country,
        setCountry,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
