import React from "react";
import * as S from "./PaymentMethod.styles";
import {
  Mastercard,
  Amex,
  Tick,
  Visa,
  theme,
} from "@bp/ui-components/web/index.js";

interface IProps {
  active?: boolean;
  type: string;
  onPress?: any;
  digits?: string;
  expiry?: string;
}

const PaymentMethod = (props: IProps) => {
  const { active = false, type, onPress, digits, expiry } = props;
  const renderSwitch = (param: any) => {
    switch (param) {
      case "visa":
        return (
          <S.Card>
            <Visa height="16" />
          </S.Card>
        );
      case "mastercard":
        return (
          <S.Card>
            <Mastercard height="16" />
          </S.Card>
        );
      case "amex":
        return (
          <S.Card>
            <Amex height="16" />
          </S.Card>
        );
      default:
        return null;
    }
  };
  return (
    <S.PaymentMethod
      theme={theme}
      testID="PaymentMethod"
      accessibilityLabel={`${type} "Card number ending in" ${digits}`}
      accessibilityHint={`Top up your credit using this ${type}`}
      active={active}
      onPress={onPress}
    >
      <S.PaymentMethodText>{type}</S.PaymentMethodText>
      <S.PaymentMethodDetails>
        <S.DetailsText theme={theme}>
          {renderSwitch(type)}
          <S.CardDetails>{digits}</S.CardDetails>
          <S.CardDetails> {expiry}</S.CardDetails>
        </S.DetailsText>
        {active && <Tick color={theme.selectItem.enabled.text} testId="Tick" />}
      </S.PaymentMethodDetails>
    </S.PaymentMethod>
  );
};

export default React.memo(PaymentMethod);
