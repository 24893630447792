import React from "react";
import styled from "styled-components/native";

const StyledMain = styled.View`
  position: relative;
  padding: 0;
`;

interface IProps {
  children: any;
}
const Main = (props: IProps) => {
  const { children } = props;
  return <StyledMain>{children}</StyledMain>;
};

export default Main;
