import React, { useContext } from "react";
import * as S from "./PaymentMethodList.styles";
import { PaymentMethod } from "../../Molecules";
import { AppContext } from "../../../contexts/app.context";
import { useHistory } from "react-router-dom";
import { theme } from "@bp/ui-components/web/index.js";

type Method = {
  id: string;
  type: string;
  expiry: string;
  digits: string;
};
export interface IProps {
  methods: Method[];
}

export default (props: IProps) => {
  const { methods } = props;
  const history = useHistory();
  const appContext = useContext(AppContext);

  const onPress = (method: Method) => {
    appContext.setState({
      ...appContext.state,
      paymentMethod: {
        id: method.id,
        type: method.type,
        expiry: method.expiry,
        digits: method.digits,
      },
    });
  };
  return (
    <>
      <S.HeadingContainer>
        <S.HeadingText>Select payment type</S.HeadingText>

        <S.LinkText
          onPress={() => {
            history.push("/method");
          }}
          accessibilityLabel={"Add payment card"}
          testID={"Add payment card"}
          theme={theme}
        >
          Add new
        </S.LinkText>
      </S.HeadingContainer>
      <S.ItemContainer />
      <S.ListWrapper>
        {methods.map((method, i) => {
          return (
            <S.ItemContainer key={i}>
              <PaymentMethod
                onPress={() => onPress(method)}
                active={appContext.state.paymentMethod.id === method.id}
                type={method.type}
                expiry={method.expiry}
                digits={method.digits}
              />
            </S.ItemContainer>
          );
        })}
      </S.ListWrapper>
    </>
  );
};
