import styled from "styled-components/native";
import { Link } from "react-router-dom";

export const ItemContainer = styled.View`
  padding-bottom: 10px;
`;

export const HeadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ListWrapper = styled.View`
  padding-bottom: 70px;
`;

export const HeadingText = styled.Text`
  font-size: 16px;
`;

export const LinkText = styled.Text`
  font-size: 16px;
  color: ${(props: any) => props.theme.text.color.tertiary};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
