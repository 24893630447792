import styled from "styled-components/native";

export const CardFormWrapper = styled.View`
  padding: 20px;
  display: flex;
`;

export const CardElementWrapper = styled.View`
  padding: 20px;
`;

export const CardWrapper = styled.View`
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #DEDEDE;
  border-radius: 8px;
`;

export const HeadingText = styled.Text`
  font-size: 16px;
`;
export const InputField = styled.TextInput`
  padding: 20px;
  font-size: 16px;
`;

export const Loading = styled.View`
  position: absolute;
  bottom: 10px;
  background: #fff;
  z-index: 100;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 10px;
`;

export const ButtonWrapper = styled.View`
  position: fixed !important;
  bottom: 20px;
  width: calc(100% - 40px);
`;
