import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components/native';
import {theme} from '@bp/ui-components/web/index.js';

const StyleWrapper = ({children}) => (
  <ThemeProvider theme={theme}>
    <>{children}</>
  </ThemeProvider>
);

StyleWrapper.propTypes = {
  children: PropTypes.node,
};

StyleWrapper.defaultProps = {
  children: null,
};

export default StyleWrapper;
