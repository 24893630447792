import styled from 'styled-components';

export const Dropdown = styled.select`
  font-size: 16px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  height: 55px;
  padding: 0 50px 0 20px;
  background: #ffffff;
  max-width: 100%;
  border-radius: 26px;
  width: -webkit-fill-available;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Arrow = styled.i`
  height: 1px;
  margin: auto 0;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: auto -30px;
`;
