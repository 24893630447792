import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  CREATE_PAYMENT_INTENT,
  GET_CARDS,
  GET_STRIPE_CUSTOMER,
} from "../../../graphql/queries";
import { AppContext } from "../../../contexts/app.context";
import * as S from "./TopUp.styles";
import {
  TopUpList,
  PaymentMethodList,
  ErrorBanner,
  PaymentMethod,
} from "../../Molecules";
import { Button, Spinner, Tick } from "@bp/ui-components/web/index.js";
import { useLocation } from "react-router";

interface CardData {
  id: string;
  card: Card;
}
interface Card {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

const TopUp = () => {
  const stripe = useStripe();
  const appContext = useContext(AppContext);
  const [errorState, setErrorState] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [createPaymentIntent, { data, loading }] = useLazyQuery(
    CREATE_PAYMENT_INTENT
  );
  const [getCardData, { data: cardData, loading: cardsLoading }] = useLazyQuery(
    GET_CARDS,
    {
      fetchPolicy: "network-only",
    }
  );
  const elements = useElements();
  const [
    getCustomer,
    { data: customerData, loading: customerLoading, error: customerError },
  ] = useLazyQuery(GET_STRIPE_CUSTOMER);

  useEffect(() => {
    if (customerData) {
      appContext.setState({
        ...appContext.state,
        stripeId: customerData.getCustomer.data,
      });
      setPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  // Handle inbound message from react-native webview
  const handleMessage = (e: any) => {
    const parsedMessage = JSON.parse(e.data);
    parsedMessage.user &&
      appContext.setState({
        ...appContext.state,
        id_token: parsedMessage.user.id_token,
        sfId: parsedMessage.user.sfId,
        email: parsedMessage.user.email,
      });
    parsedMessage.user &&
      getCustomer({
        variables: {
          sfId: parsedMessage.user.sfId,
          email: parsedMessage.user.email,
        },
      });
  };

  useEffect(() => {
    document.addEventListener("message", handleMessage);
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
      document.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerError) {
      setErrorMessage("Please check your web connection or try again");
      setErrorState(true);
    }
    cardData && appContext.setSavedCardData(cardData.getSavedCards.data);

    if (
      data &&
      appContext.state.paymentIntent !== data.createPaymentIntent.data
    ) {
      appContext.setState({
        ...appContext.state,
        paymentIntent: data.createPaymentIntent.data,
      });
      appContext.setTopUpFormState("selectPaymentMethod");
      appContext.state.stripeId &&
        getCardData({
          variables: {
            stripeId: appContext.state.stripeId,
            sfId: appContext.state.sfId,
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, appContext, customerError, customerLoading, cardData]);

  const submitTopUpAmount = () => {
    if (!stripe || !elements) {
      setPageLoading(true);
      return;
    }
    createPaymentIntent({
      variables: {
        stripeId: appContext.state.stripeId,
        sfId: appContext.state.sfId,
        email: appContext.state.email,
        amount: appContext.state.topUpValue * 100,
      },
    });
    //Analytics data when user starts new top up (counts total number of top ups)
    // let amount = appContext.state.topUpValue * 100;
    // const gAParams = [
    //   { key: "event_status", value: "success" },
    //   { key: "top_up_ammount_selected", value: amount },
    //   {
    //     key: "payment_method_type",
    //     value: appContext.state.paymentMethod.type,
    //   },
    //   { key: "payment_card_type", value: "" },
    // ];

    // googleAnalyticsFnWeb.googleAnalyticsEvent(
    //   "Top_Ups_Started",
    //   "Top Ups Started",
    //   "",
    //   gAParams
    // );
  };

  const submitTopUpFinal = async () => {
    if (!stripe || !elements) {
      setPageLoading(true);
      return;
    }
    setPageLoading(true);

    const result = await stripe.confirmCardPayment(
      appContext.state.paymentIntent,
      { payment_method: appContext.state.paymentMethod.id }
    );
    if (result.error) {
      setPageLoading(false);
      if (result.error.type === "card_error") {
        setErrorMessage(
          "Please check your card details and that you have sufficient funds before re-trying"
        );
      } else {
        setErrorMessage("Please check your web connection or try again");
      }
      setErrorState(true);
    } else {
      setPageLoading(false);
      //Basic GA event params needs to be added
      //Analytics data when user starts new top up (counts total number of top ups)
      // let amount = appContext.state.topUpValue * 100;
      // const gAParams = [
      //   { key: "event_status", value: "success" },
      //   { key: "top_up_ammount_selected", value: amount },
      //   {
      //     key: "payment_method_type",
      //     value: appContext.state.paymentMethod.type,
      //   },
      //   { key: "payment_card_type", value: "" },
      // ];

      appContext.setTopUpFormState("success");
      (window as any).window["ReactNativeWebView"].postMessage("success");

      // googleAnalyticsFnWeb.googleAnalyticsEvent(
      //   "Top_Ups_Started",
      //   "Top Ups Started",
      //   "",
      //   gAParams
      // );
    }
  };

  let location = useLocation();
  useEffect(() => {
    appContext.state.stripeId &&
      getCardData({
        variables: {
          stripeId: appContext.state.stripeId,
          sfId: appContext.state.sfId,
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <S.TopUpFormWrapper>
      {appContext.topUpFormState === "selectAmount" &&
        (pageLoading ? (
          <S.Loading>
            <Spinner active />
          </S.Loading>
        ) : (
          <>
            <TopUpList amounts={[5, 10, 20, 40]} />
            <S.BottomButton>
              <Button
                type="primary"
                testID={"Next, Navigates to 'Select your payment card' screen"}
                accessibilityLabel={"Next"}
                accessibilityHint={
                  "Navigates to 'Select your payment card' screen"
                }
                onPress={() => {
                  submitTopUpAmount();
                }}
                disabled={appContext.state.topUpValue === 0 || customerLoading}
              >
                Continue
              </Button>
            </S.BottomButton>
          </>
        ))}
      {appContext.topUpFormState === "selectPaymentMethod" && (
        <>
          {errorState && <ErrorBanner errorMessage={errorMessage} />}
          {cardsLoading || loading ? (
            <S.Loading>
              <Spinner active />
            </S.Loading>
          ) : (
            appContext.savedCardData && (
              <PaymentMethodList
                methods={appContext.savedCardData.map(
                  (paymentCard: CardData) => {
                    return {
                      id: paymentCard.id,
                      type: paymentCard.card.brand,
                      expiry: `${paymentCard.card.exp_month.toString()}/${paymentCard.card.exp_year
                        .toString()
                        .substr(-2)}`,
                      digits: paymentCard.card.last4,
                    };
                  }
                )}
              ></PaymentMethodList>
            )
          )}
          {appContext.savedCardData.length === 0 && (
            <S.NoPaymentText>No payment cards added.</S.NoPaymentText>
          )}
          <S.BottomButton>
            <Button
              type="primary"
              testID={"Pay now, Process your top up payment"}
              accessibilityLabel={"Pay now"}
              accessibilityHint={"Process your top up payment"}
              onPress={() => {
                submitTopUpFinal();
              }}
              disabled={appContext.state.paymentMethod.id === ""}
            >
              Pay now
            </Button>
          </S.BottomButton>
        </>
      )}
      {appContext.topUpFormState === "success" && (
        <S.ResultWrapper>
          <S.IconTextContainer>
            <Tick width={18} height={14} color="#006DEF" />
            <S.SuccessText>Success!</S.SuccessText>
          </S.IconTextContainer>
          <S.HeadingText>
            We've processed your payment and are updating your credit.
          </S.HeadingText>
          <PaymentMethod
            expiry={appContext.state.paymentMethod.expiry}
            digits={appContext.state.paymentMethod.digits}
            type={appContext.state.paymentMethod.type}
          ></PaymentMethod>
        </S.ResultWrapper>
      )}
    </S.TopUpFormWrapper>
  );
};

export default TopUp;
