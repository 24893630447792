import styled from "styled-components/native";

export const ItemContainer = styled.View`
  height: 65px;
`;

export const HeadingContainer = styled.View`
  height: 35px;
`;

export const HeadingText = styled.Text`
  text-align: flex-start;
  font-size: 16px;
`;
